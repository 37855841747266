import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Shippings = lazy(() => import('../../container/shippings/Shippings'));

const ShipppingRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path="/my-shipments" component={Shippings} />
    </Switch>
  );
};

export default ShipppingRoutes;
