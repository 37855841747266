import { Auth as AmplifyAuth } from 'aws-amplify';
import axios from 'axios';
import env from '../config/constants';

const API = {
  endpoint: env.apiUrl,
  get: async path => {
    try {
      const idToken = (await AmplifyAuth.currentSession()).getIdToken().getJwtToken();

      return await axios.get(`${API.endpoint}/${path}`, {
        headers: {
          Authorization: idToken,
        },
      });
    } catch (err) {
      return { error: 'Error while fetching' };
    }
  },
  put: async (path, fields) => {
    try {
      const idToken = (await AmplifyAuth.currentSession()).getIdToken().getJwtToken();
      //
      //
      return await axios.put(`${API.endpoint}/${path}`, fields, {
        headers: {
          Authorization: idToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    } catch (err) {
      return { error: 'Error while fetching' };
    }
  },
  post: async (path, fields) => {
    try {
      const idToken = (await AmplifyAuth.currentSession()).getIdToken().getJwtToken();
      //
      //
      return await axios.post(`${API.endpoint}/${path}`, fields, {
        headers: {
          Authorization: idToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    } catch (err) {
      return { error: 'Error while fetching' };
    }
  },
  delete: async path => {
    try {
      const idToken = (await AmplifyAuth.currentSession()).getIdToken().getJwtToken();

      return await axios.delete(`${API.endpoint}/${path}`, {
        headers: {
          Authorization: idToken,
        },
      });
    } catch (err) {
      return { error: 'Error while fetching' };
    }
  },
};

const codeParse = {
  CodeMismatchException: 'El código de recuperación es incorrecto',
  UserNotFoundException: 'El usuario no existe',
  LimitExceededException: 'Has superado el limite de códigos enviados, espera unos minutos y vuelve a intentarlo',
  ExpiredCodeException: 'El código de verificación expiró',
  TooManyFailedAttemptsException:
    'Has superado el limite de intentos de inicio de sesión, espera unos minutos y vuelve a intentarlo',
  NotAuthorizedException: 'Los datos ingresados son incorrectos',
};

const errorParser = code => {
  const error = codeParse[code];
  return error;
};

const formatDate = date => {
  const formattedDate = new Date(date * 1000);
  const str = `${formattedDate.getMonth() + 1}/${formattedDate.getDate()}/${formattedDate.getFullYear()}`;

  return str;
};

const formatHour = date => {
  const newDate = new Date(date * 1000);
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};

export { API, formatDate, formatHour, errorParser };
