const actions = {
  SET_CLIENT_PAGINATION: 'SET_CLIENT_PAGINATION',

  setClientData: data => {
    return {
      type: actions.SET_CLIENT_PAGINATION,
      data,
    };
  },
};

export default actions;
